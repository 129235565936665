<template>
  <div id="app" style="background-color: #FFFFFF; height: 100vh;">
    <div style="text-align: center; padding-top: 12vh">
      <img
          style="width: 90px; height: 90px;"
          :src="require('../../../src/assets/images/kitchen/icon_login.png')"
          alt=""/>
      <div style="font-size: 18px; font-weight: 600; margin-top: 16px;">一天膳事®智慧食安云平台</div>
    </div>

    <div
        style="border-radius: 2px; border: 1px solid #E7F7F7; margin-right: 30px; margin-left: 30px; margin-top: 46px;">
      <div style="display: flex; padding-top: 4px; padding-bottom: 4px;">
        <img
            style="width: 16px; height: 16px; align-self: center; margin-left: 19px;"
            :src="require('../../../src/assets/images/kitchen/icon_username.png')"
            alt=""/>
        <van-field
            v-model="data.username"
            type="text"
            maxlength="11"
            placeholder="请输入账号"
            @input="checkIsOkToConfirm"
        />
      </div>
      <div style="background-color: #E7E7E7; height: 1px; width: 100%"/>
      <div style="display: flex; padding-top: 4px; padding-bottom: 4px;">
        <img
            style="width: 16px; height: 16px; align-self: center; margin-left: 19px;"
            :src="require('../../../src/assets/images/kitchen/icon_password.png')"
            alt=""/>
        <van-field
            v-model="data.password"
            type="password"
            maxlength="11"
            placeholder="请输入密码"
            @input="checkIsOkToConfirm"
        />
      </div>
    </div>

    <div
        style="margin-right: 30px; margin-left: 30px; background: linear-gradient(90deg, #39D148, #34AC40); border-radius: 7px; height: 45px; margin-top: 46px; color: #FFFFFF; font-size: 16px; text-align: center; line-height: 45px;"
        v-if="isOkToConfirm"
        @click="onClickConfirm">
      登录
    </div>

    <div
        style="margin-right: 30px; margin-left: 30px; background: #E1E1E1; border-radius: 7px; height: 45px; margin-top: 46px; color: #FFFFFF; font-size: 16px; text-align: center; line-height: 45px;"
        v-if="!isOkToConfirm">
      登录
    </div>

    <img
        style="width: 100vw; position: absolute; bottom: 0;"
        :src="require('../../../src/assets/images/kitchen/bg_login.png')"
        alt=""/>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>{{ loadingText }}</van-loading>
    </div>
  </div>
</template>

<script>
import {Notify} from "vant";
import Vue from "vue";
import {appProd, kitchenLoginParam, appId} from '@/config/env.dev'
import {KITCHEN_ACCESS_TOKEN, CURRENT_BUSINESS_MODULE, KITCHEN_PASSWORD, KITCHEN_USERNAME} from "../../store/mutation-types";
import {post} from "../../utils/httpKitchen";

export default {
  name: "login",
  data() {
    return {
      credential: '',
      data: {
        username: "",
        password: ""
      },
      isOkToConfirm: false,
      loading: false,
      loadingText: '登录中,请稍后...'
    }
  },
  mounted() {
    // 默认登录
    if (Vue.ls.get(KITCHEN_ACCESS_TOKEN)) {
      Vue.ls.set(KITCHEN_ACCESS_TOKEN, null);
      this.data.username = Vue.ls.get(KITCHEN_USERNAME);
      this.data.password = Vue.ls.get(KITCHEN_PASSWORD);

      this.grant();

      return;
    }

    var query = this.$route.query;
    if (query.code) {
      this.credential = query.code;

      this.data.username = Vue.ls.get(KITCHEN_USERNAME);
      this.data.password = Vue.ls.get(KITCHEN_PASSWORD);

      this.login();

      return;
    }

  },
  methods: {
    onClickConfirm() {
      if (!this.data.username) {
        Notify({type: "danger", message: '请输入账号'});
        return;
      }
      if (!this.data.password) {
        Notify({type: "danger", message: '请输入密码'});
        return;
      }
      this.grant();
    },
    grant() {
      Vue.ls.set(CURRENT_BUSINESS_MODULE, 'kitchen');
      Vue.ls.set(KITCHEN_USERNAME, this.data.username);
      Vue.ls.set(KITCHEN_PASSWORD, this.data.password);

      var domine = window.location.href.split('#')[0] // 微信会自动识别#    并且清除#后面的内
      var url = encodeURI(domine);

      if (!appProd) {
        url = 'http://xuexiao.fsytss.com/testLogin';
      }

      let jumpUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          appId +
          '&redirect_uri=' +
          url +
          '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
      window.location.href = jumpUrl
    },
    login() {
      this.loading = true;
      kitchenLoginParam.username = this.credential + "/" + this.data.username;
      kitchenLoginParam.credential = this.data.password;

      post('api/authorize/v1/grant', kitchenLoginParam, false).then(res => {
        // 设置30天的令牌时限
        Vue.ls.set(KITCHEN_ACCESS_TOKEN, res.data.accessToken, 30 * 24 * 60 * 60 * 1000);
        this.$router.push({path: '/kitchen/index'});
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
      }).finally(() => {
        this.loading = false;
      })
    },
    checkIsOkToConfirm() {
      if (!this.data.username) {
        this.isOkToConfirm = false;
        return;
      }
      if (!this.data.password) {
        this.isOkToConfirm = false;
        return;
      }
      this.isOkToConfirm = true;
    },
  }
}
</script>

<style scoped>

</style>